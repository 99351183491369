.bcp47-picker {
  display: flex;
  width: 100%;
  max-width: 100%; 
  position: relative;
  flex-direction: column;
}

.bcp47-picker.loading,
.bcp47-picker.loading * {
  cursor: wait;
}

.bcp47-results {
  position: absolute;
  width: 100%;
  top: 100%;
  z-index: 10;
  max-height: 100vh;
  overflow-y: auto;

  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem #b4d2ff;
}

.bcp47-picker.focused .bcp47-value {
  border-color: #86b7fe;
  outline: 0;
  border-radius: 5px;
  box-shadow: 0 0 0 .25rem #b4d2ff;
}

.bcp47-current-value-part .bcp47-inner {
  display: flex;
}

.bcp47-current-value-part {
  display: flex;
}

.bcp47-search-wrapper {
  position: relative;
}

.bcp47-value-label {
  user-select: none;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bcp47-value-bcp47 {
  flex: 0 0 auto;
  margin-left: 7px;
}

.bcp47-picker.bootstrap .bcp47-current-value-part.form-floating input:not(:focus) {
  color: white;
}

.bcp47-picker.bootstrap .bcp47-current-value-part.form-floating input:focus ~ .bcp47-current-value-part-value {
      display: none;
    }

.bcp47-picker.bootstrap .bcp47-current-value-part.form-floating .bcp47-current-value-part-value {
  position: absolute;
  top: 26px;
  left: 13px;
  pointer-events: none;
}  

.bcp47-remove-value {
  cursor: pointer;
  margin-left: 5px;
}

.bcp47-search.form-control {
  text-overflow: ellipsis;
  flex: 1 1 auto !important;
  width: 10px;
}

.bcp47-search.form-control:focus {
  box-shadow: none;
}

.bcp47-search.form-control.hide-borders {
  border: none;
  margin: -5px;
}

.bcp47-search.form-control.hide-borders:focus {
  box-shadow: none;
}

.bcp47-value-wrapper {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 1 1 auto !important;
  width: auto !important;
  display: flex !important;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  max-width: 100%;
}

.bcp47-observer-spacer {
  flex: 0 0 var(--items);
}

.bcp47-value {
  min-height: 40px;
}

.bcp47-value-item {
  display: inline-flex;
}

.bcp47-value > .bcp47-search:nth-child(2) {
  border-left: none;
}

.bcp47-value.input-group {
  flex-wrap: nowrap;
}

.bcp47-picker[multiple] .bcp47-value-label {
  cursor: pointer;
}

.bcp47-picker[multiple] .bcp47-value-label.active {
  font-weight: bold;
}

.bcp47-name-span {
  white-space: nowrap;
}

.bcp47-result.active .text-muted {
  color: white !important;
}