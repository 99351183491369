.bcp47-picker {
  max-width: 100%;
  width: 100%;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.bcp47-picker.loading, .bcp47-picker.loading * {
  cursor: wait;
}

.bcp47-results {
  max-height: 100vh;
  width: 100%;
  z-index: 10;
  position: absolute;
  top: 100%;
  overflow-y: auto;
}

.bcp47-picker.focused .bcp47-value, .bcp47-results {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem #b4d2ff;
}

.bcp47-picker.focused .bcp47-value {
  border-radius: 5px;
}

.bcp47-current-value-part, .bcp47-current-value-part .bcp47-inner {
  display: -ms-flexbox;
  display: flex;
}

.bcp47-search-wrapper {
  position: relative;
}

.bcp47-value-label {
  text-overflow: ellipsis;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: block;
  overflow: hidden;
}

.bcp47-value-bcp47 {
  -ms-flex: none;
  flex: none;
  margin-left: 7px;
}

.bcp47-picker.bootstrap .bcp47-current-value-part.form-floating input:not(:focus) {
  color: #fff;
}

.bcp47-picker.bootstrap .bcp47-current-value-part.form-floating input:focus ~ .bcp47-current-value-part-value {
  display: none;
}

.bcp47-picker.bootstrap .bcp47-current-value-part.form-floating .bcp47-current-value-part-value {
  pointer-events: none;
  position: absolute;
  top: 26px;
  left: 13px;
}

.bcp47-remove-value {
  cursor: pointer;
  margin-left: 5px;
}

.bcp47-search.form-control {
  text-overflow: ellipsis;
  width: 10px;
  -ms-flex: auto !important;
  flex: auto !important;
}

.bcp47-search.form-control:focus {
  box-shadow: none;
}

.bcp47-search.form-control.hide-borders {
  border: none;
  margin: -5px;
}

.bcp47-search.form-control.hide-borders:focus {
  box-shadow: none;
}

.bcp47-value-wrapper {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  width: auto !important;
  -ms-flex: auto !important;
  flex: auto !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.bcp47-observer-spacer {
  flex: 0 0 var(--items);
}

.bcp47-value {
  min-height: 40px;
}

.bcp47-value-item {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.bcp47-value > .bcp47-search:nth-child(2) {
  border-left: none;
}

.bcp47-value.input-group {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.bcp47-picker[multiple] .bcp47-value-label {
  cursor: pointer;
}

.bcp47-picker[multiple] .bcp47-value-label.active {
  font-weight: 700;
}

.bcp47-name-span {
  white-space: nowrap;
}

.bcp47-result.active .text-muted {
  color: #fff !important;
}

.bcp47-picker {
  width: 100%;
  max-width: 100%;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.bcp47-picker.loading, .bcp47-picker.loading * {
  cursor: wait;
}

.bcp47-results {
  width: 100%;
  z-index: 10;
  max-height: 100vh;
  border-color: #86b7fe;
  outline: 0;
  position: absolute;
  top: 100%;
  overflow-y: auto;
  box-shadow: 0 0 0 .25rem #b4d2ff;
}

.bcp47-picker.focused .bcp47-value {
  border-color: #86b7fe;
  border-radius: 5px;
  outline: 0;
  box-shadow: 0 0 0 .25rem #b4d2ff;
}

.bcp47-current-value-part .bcp47-inner, .bcp47-current-value-part {
  display: -ms-flexbox;
  display: flex;
}

.bcp47-search-wrapper {
  position: relative;
}

.bcp47-value-label {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.bcp47-value-bcp47 {
  -ms-flex: none;
  flex: none;
  margin-left: 7px;
}

.bcp47-picker.bootstrap .bcp47-current-value-part.form-floating input:not(:focus) {
  color: #fff;
}

.bcp47-picker.bootstrap .bcp47-current-value-part.form-floating input:focus ~ .bcp47-current-value-part-value {
  display: none;
}

.bcp47-picker.bootstrap .bcp47-current-value-part.form-floating .bcp47-current-value-part-value {
  pointer-events: none;
  position: absolute;
  top: 26px;
  left: 13px;
}

.bcp47-remove-value {
  cursor: pointer;
  margin-left: 5px;
}

.bcp47-search.form-control {
  text-overflow: ellipsis;
  width: 10px;
  -ms-flex: auto !important;
  flex: auto !important;
}

.bcp47-search.form-control:focus {
  box-shadow: none;
}

.bcp47-search.form-control.hide-borders {
  border: none;
  margin: -5px;
}

.bcp47-search.form-control.hide-borders:focus {
  box-shadow: none;
}

.bcp47-value-wrapper {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  width: auto !important;
  -ms-flex: auto !important;
  flex: auto !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.bcp47-observer-spacer {
  flex: 0 0 var(--items);
}

.bcp47-value {
  min-height: 40px;
}

.bcp47-value-item {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.bcp47-value > .bcp47-search:nth-child(2) {
  border-left: none;
}

.bcp47-value.input-group {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.bcp47-picker[multiple] .bcp47-value-label {
  cursor: pointer;
}

.bcp47-picker[multiple] .bcp47-value-label.active {
  font-weight: bold;
}

.bcp47-name-span {
  white-space: nowrap;
}

.bcp47-result.active .text-muted {
  color: #fff !important;
}

/*# sourceMappingURL=index.88c9cabe.css.map */
